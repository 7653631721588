import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { ColumnPanel, BlogCard, AccordionPanel, AccordionItem } from 'andorlh-ui';
import { FaCrown } from "react-icons/fa";

export class PanelFaqs extends Component {

    constructor(props) {
        super(props);
    }

    renderLabel() {
        if (!this.props.information) {
            return;
        }
        if (!this.props.information.faqsSection) {
            return;
        }
        return (
            <div className="div-header">
                <h1>
                    {this.props.information.faqsSection.label}
                </h1>
                <p >
                    {this.props.information.faqsSection.description}
                </p>
            </div>
        );
    }

    renderItems() {
        let elements = [];
        if (!this.props.information) {
            return;
        }
        if (!this.props.information.faqsSection) {
            return;
        }
        if (!this.props.information.faqsSection.faqs) {
            return;
        }
        for (var i = 0; i < this.props.information.faqsSection.faqs.length; i++) {
            let item = this.props.information.faqsSection.faqs[i];
            elements.push(
                <AccordionItem title={"¿" + item.question + "?"}>
                    <p>{item.answer}</p>
                </AccordionItem>
            )
        }
        return elements;
    }

    render() {
        let me = this;
        return (
            <div className={this.props.className} >
                <div className="div-content">
                    {this.renderLabel()}
                    <AccordionPanel className="AccordionPanel">
                        {this.renderItems()}
                    </AccordionPanel>
                </div>
            </div>
        );
    }
}
