import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { ColumnPanel, BlogCard } from 'andorlh-ui';
import { FaCrown } from "react-icons/fa";

export class PanelFeatures extends Component {

    constructor(props) {
        super(props);
    } 

    render() {
        let me = this;
        return (
            <div className="panel-benefits">
                <div className="div-container">
                    <div className="div-content">
                        <ColumnPanel className="panel-items" >
                            <div className="panel-item">
                                <div className="div-container">
                                    <div className="div-content">
                                        <span className="icon">
                                            <FaCrown />
                                        </span>
                                        <span>{" "}</span>
                                        <span>Feature 1</span>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-item">
                                <div className="div-container">
                                    <div className="div-content">
                                        <span className="icon">
                                            <FaCrown />
                                        </span>
                                        <span>{" "}</span>
                                        <span>Feature 2</span>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-item">
                                <div className="div-container">
                                    <div className="div-content">
                                        <span className="icon">
                                            <FaCrown />
                                        </span>
                                        <span>{" "}</span>
                                        <span>Feature 3</span>
                                    </div>
                                </div>
                            </div>
                        </ColumnPanel>
                    </div>
                </div>
            </div>  
        );
    }
}
