import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { SlideshowPanel, BlogCard } from 'andorlh-ui';

export class PanelProducts extends Component {

    constructor(props) {
        super(props);
    }

    renderHeader() {
        let elements = [];
        if (!this.props.information) {
            return;
        }
        if (!this.props.information.productsSection) {
            return;
        }
        if (this.props.information.productsSection.label) {
            elements.push(
                <h1>
                    {this.props.information.productsSection.label}
                </h1>
            );
        }
        if (this.props.information.productsSection.description) {
            elements.push(
                <p >
                    {this.props.information.productsSection.description}
                </p>
            );
        }
        return (
            <div className="div-header">
                {elements}
            </div>
        );
    }

    renderItems() {
        let elements = [];
        if (!this.props.information) {
            return;
        }
        if (!this.props.information.productsSection) {
            return;
        }
        if (!this.props.information.productsSection.products) {
            return;
        }
        for (var i = 0; i < this.props.information.productsSection.products.length; i++) {
            let item = this.props.information.productsSection.products[i];
            elements.push(
                <div className="div-product">
                    <div className="div-image">
                        <a href={"/products/" + item.id} data-toggle="modal" data-target="#1" >
                            <img src={"images/products/product" + item.id + ".png"} alt="" />
                        </a>
                    </div>
                    <div className="div-information">
                        <h1>{item.name}</h1>
                        <h2>{item.brand}</h2>
                        <p>{item.description}</p>
                        <button href={"/products/" + item.id} className="button-1">Saber m�s</button>
                    </div>
                </div>
            )
        }
        return elements;
    }

    render() {
        let me = this;
        return (
            <div className={this.props.className}>
                <div className="div-content">
                    {this.renderHeader()}
                    <SlideshowPanel className="SlideshowPanel" auto="false" timeout="7" pagination="true">
                        {this.renderItems()}
                    </SlideshowPanel>
                </div>
            </div >
        );
    }
}
