import React, { Component } from 'react';
import { PanelHero } from '../controls/PanelHero';
import { PanelBenefits } from '../controls/PanelBenefits';
import { PanelTestimonials } from '../controls/PanelTestimonials';
import { PanelProducts } from '../controls/PanelProducts';
import { PanelFeatures } from '../controls/PanelFeatures';
import { PanelFaqs } from '../controls/PanelFaqs';
import { PanelPricing } from '../controls/PanelPricing';
import { PanelBlog } from '../controls/PanelBlog';
import { PanelGallery } from '../controls/PanelGallery';
import { PanelCustomers } from '../controls/PanelCustomers';
import { PanelSolutions } from '../controls/PanelSolutions';
import { PanelTeam } from '../controls/PanelTeam';
import { PanelSpecial } from '../controls/PanelSpecial';
import SettingsContext from '../../contexts/SettingsContext';

export class Home extends Component {
    static displayName = Home.name;
    static contextType = SettingsContext;

    constructor(props) {
        super(props);
        this.state = {
            information: {}
        }
    }

    componentDidMount() {
        let me = this;
        this.setState({
            information: this.context.information
        }) 
        window.scrollTo(0, 0);
    }

    renderSolutions() {
        let elements = [];
        let information = this.context.information;
        if (information.solutionsSection) {
            for (var i = 0; i < information.solutionsSection.solutions.length; i++) {
                elements.push(
                    <div className="div-section div-PanelSolutions">
                        <PanelSolutions className="PanelSolutions" information={information.solutionsSection.solutions[i]} />
                    </div>
                )
            }
        }
        return elements;
    }

    render() {
        let me = this;
        let information = this.context.information;
        return (
            <div className="Home">
                <div className="div-content">
                    <div className="div-PanelHero">
                        <PanelHero className="PanelHero" information={information} />
                    </div>
                    <div className="div-section div-PanelBenefits">
                        <PanelBenefits className="PanelBenefits" information={information} />
                    </div>
                    {this.renderSolutions()}
                    {/*<div className="div-section div-PanelProducts">*/}
                    {/*    <PanelProducts className="PanelProducts" information={information} />*/}
                    {/*</div>*/}
                    {/*<div className="div-section div-PanelFeatures">*/}
                    {/*    <PanelFeatures className="PanelFeatures" information={information} />*/}
                    {/*</div>*/}
                    {/*<div className="div-section div-PanelSpecial">*/}
                    {/*    <PanelSpecial className="PanelSpecial" information={information} />*/}
                    {/*</div>*/}
                    {/*<div className="div-section div-PanelTeam">*/}
                    {/*    <PanelTeam className="PanelTeam" information={information} />*/}
                    {/*</div>*/}
                    {/*<div className="div-section div-PanelGallery">*/}
                    {/*    <PanelGallery information={information} />*/}
                    {/*</div>*/}

                    {/*<div className="div-section div-PanelBlog">*/}
                    {/*    <PanelBlog className="PanelBlog" information={information} />*/}
                    {/*</div>*/}
                    {/*<div className="div-section div-PanelPricing">*/}
                    {/*    <PanelPricing className="PanelPricing" information={information} />*/}
                    {/*</div>*/}
                    <div className="div-section div-PanelTestimonials">
                        <PanelTestimonials className="PanelTestimonials" information={information} />
                    </div>
                    <div className="div-section div-PanelCustomers">
                        <PanelCustomers className="PanelCustomers" information={information} />
                    </div>
                    {/*<div className="div-section div-PanelFaqs">*/}
                    {/*    <PanelFaqs className="PanelFaqs" information={information} />*/}
                    {/*</div>*/}
                </div>
            </div>
        );
    }
}
