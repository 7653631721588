import React, { Component } from 'react';
import { Container } from 'reactstrap'; 
import { ColumnPanel, BlogCard } from 'andorlh-ui';

export class PanelBlog extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let me = this;
        return (
            <div className="panel-blog">
                <div className="div-container">
                    <div className="div-content">
                        <h1>
                            Our Blog
                        </h1> 
                    </div>
                    <div>
                        <button className="button-1">View the Blog</button>
                    </div>
                </div>
            </div >
        );
    }
}
