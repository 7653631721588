import React, { Component } from 'react';
import { Container } from 'reactstrap'; 
import { ColumnPanel, BlogCard } from 'andorlh-ui';

export class PanelSpecial extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let me = this;
        return (
            <div className="panel-special">
                <div className="div-container">
                    <div className="div-content">
                        <div>
                            <h1 >
                                Today's Special
                            </h1>
                            <p >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusm incididunt ut labore et dolore magna aliqua. Ut enim ad minim venia,nostrud exercitation ullamco.</p>
                        </div> 
                    </div>
                </div>
            </div>
        );
    }
}
