import React, { Component } from 'react';
import { Container } from 'reactstrap'; 
import { SlideshowPanel, BlogCard } from 'andorlh-ui';

export class PanelGallery extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let me = this;
        return (
            <div className="panel-gallery">
                <div className="div-container">
                    <div className="div-content">
                        <div>
                            <h1>
                                Our Gallery
                            </h1>
                            <p>There are many variations of passages of Lorem Ipsum available</p>
                        </div>

                        <SlideshowPanel className="panel-items" auto="true" timeout="7" pagination="true">
                            <a href="#" data-toggle="modal" data-target="#1">
                                <img src="images/gallery_01.jpg" alt="" />
                            </a>
                            <a href="#" data-toggle="modal" data-target="#2">
                                <img src="images/gallery_02.jpg" alt="" />
                            </a>
                            <a href="#" data-toggle="modal" data-target="#3">
                                <img src="images/gallery_03.jpg" alt="" />
                            </a>
                            <a href="#" data-toggle="modal" data-target="#4">
                                <img src="images/gallery_04.jpg" alt="" />
                            </a>
                            <a href="#" data-toggle="modal" data-target="#5">
                                <img src="images/gallery_05.jpg" alt="" />
                            </a>
                            <a href="#" data-toggle="modal" data-target="#9">
                                <img src="images/gallery_06.jpg" alt="" />
                            </a>
                            <a href="#" data-toggle="modal" data-target="#10">
                                <img src="images/gallery_07.jpg" alt="" />
                            </a>
                            <a href="#" data-toggle="modal" data-target="#11">
                                <img src="images/gallery_08.jpg" alt="" />
                            </a>
                            <a href="#" data-toggle="modal" data-target="#12">
                                <img src="images/gallery_09.jpg" alt="" />
                            </a>
                            <a href="#" data-toggle="modal" data-target="#13">
                                <img src="images/gallery_10.jpg" alt="" />
                            </a>
                        </SlideshowPanel>
                    </div>
                </div> 
            </div >
        );
    }
}
