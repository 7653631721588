import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import { ColumnPanel, BlogCard } from 'andorlh-ui';

export class PanelHero extends Component {

    constructor(props) {
        super(props);
    }

    renderHero() {
        let elements = [];
        if (!this.props.information) {
            return;
        }
        if (!this.props.information.hero) {
            return;
        }
        elements.push(
            <h1>{this.props.information.hero.label}</h1>
        );
        elements.push(
            <p>{this.props.information.hero.description}</p>
        );
        return elements;
    }

    render() {
        let me = this;
        return (
            <div className={this.props.className}>
                <div className="div-content">
                    <div className="div-image">
                        <img src="/images/hero/hero_3.jpg" />
                        <div className="div-text">
                            {this.renderHero()}
                            <div>
                                <Link className="button-1" to="/quotation">Empieza ahora</Link>
                                {/*<button className="button-2">Cancel</button>{" "}*/}
                               {/* <button className="button-3">Options</button>*/}
                            </div>
                        </div>
                    </div>

                    {/*<ColumnPanel className="panel-items" >*/}
                    {/*    <div>*/}
                    {/*        {this.renderHero()}*/}
                    {/*        <div>*/}
                    {/*            <button className="button-1">Accept</button>{" "}*/}
                    {/*            <button className="button-2">Cancel</button>{" "}*/}
                    {/*            <button className="button-3">Options</button>*/}
                    {/*        </div>*/}
                    {/*        */}{/*<br />*/}
                    {/*        */}{/*<br />*/}
                    {/*        */}{/*<h1>SoftControlWeb</h1>*/}
                    {/*        */}{/*<h1>Softcontrolweb</h1>*/}
                    {/*        */}{/*<h1>SOFTCONTROLWEB</h1>*/}
                    {/*        */}{/*<h1>SCW</h1>*/}
                    {/*    </div>*/}
                    {/*    <div>*/}
                    {/*        <img src="images/about-main.jpg" alt="About Main Image" />*/}
                    {/*    </div>*/}
                    {/*</ColumnPanel>*/}
                </div>
            </div>
        );
    }
}
