import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { ColumnPanel, BlogCard } from 'andorlh-ui';
import { FaCrown } from "react-icons/fa";
import { MdOutlinePhoneAndroid } from "react-icons/md";
import { BiSolidReport } from "react-icons/bi";
import { FaClipboardList } from "react-icons/fa6";
import { BsFillPersonBadgeFill } from "react-icons/bs";
import { IoPeople } from "react-icons/io5";
import { PiFingerprintSimpleFill } from "react-icons/pi";
import { SiMinutemailer } from "react-icons/si";
import { FaGears } from "react-icons/fa6";
import { FaUserClock } from "react-icons/fa6";
import { AiFillSchedule } from "react-icons/ai";
import { IoCalendarNumber } from "react-icons/io5";

export class PanelBenefits extends Component {

    constructor(props) {
        super(props);
    }

    getIcon(name) {
        switch (name) {
            case "smartphone":
                return <MdOutlinePhoneAndroid />;
            case "report":
                return <BiSolidReport />;
            case "task":
                return <FaClipboardList />;
            case "person":
                return <BsFillPersonBadgeFill />;
            case "people":
                return <IoPeople />;
            case "biometric":
                return <PiFingerprintSimpleFill />;
            case "mail":
                return <SiMinutemailer />;
            case "system":
                return <FaGears />;
            case "clock":
                return <FaUserClock />;
            case "chair":
                return <AiFillSchedule />;
            case "check":
                return < IoCalendarNumber />;
        }
        return null;
    }

    renderItems() {
        let elements = [];
        if (!this.props.information) {
            return;
        }
        if (!this.props.information.benefits) {
            return;
        }
        for (var i = 0; i < this.props.information.benefits.length; i++) {
            let item = this.props.information.benefits[i];
            elements.push(
                <div className="div-benefit">
                    <h2>{this.getIcon(item.icon)}</h2>
                    <h5>
                        {/*<span className="icon">*/}
                        {/*    <FaCrown />*/}
                        {/*</span>*/}
                        {/*<span>{" "}</span>*/}
                        <span>{item.label}</span>
                    </h5>
                    <div>
                        <p>{item.description}</p>
                    </div>
                </div>
            )
        }
        return elements;
    }

    render() {
        let me = this;
        return (
            <div className={this.props.className}>
                <div className="div-content">
                    <ColumnPanel className="ColumnPanel" >
                        {this.renderItems()}
                    </ColumnPanel>
                </div>
            </div>
        );
    }
}
