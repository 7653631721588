import React, { Component } from 'react';
import { Container } from 'reactstrap';  
import { ColumnPanel, BlogCard } from 'andorlh-ui';

export class PanelPricing extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let me = this;
        return (
            <div className="panel-pricing">
                <div className="div-container">
                    <div className="div-content">
                        <div>
                            <h1 >
                                Pricing
                            </h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut orci varius, elementum lectus nec, aliquam lectus. Duis neque augue, maximus in sapien ut, porta pharetra odio.</p>
                        </div> 
                    </div>
                </div>
            </div>
        );
    }
}
