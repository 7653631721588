import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { SlideshowPanel, BlogCard } from 'andorlh-ui';

export class PanelCustomers extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let me = this;
        return (
            <div className={this.props.className}>
                <div className="div-content">
                    {/*<div>*/}
                    {/*    <h1>*/}
                    {/*        Customers*/}
                    {/*    </h1>*/}
                    {/*    <p>There are many variations of passages of Lorem Ipsum available</p>*/}
                    {/*</div>*/}

                    <SlideshowPanel className="SlideshowPanel" auto="true" timeout="7" pagination="false">
                        <div className="div-image">
                            <img src="images/customers/customer 1.png" alt="" />
                        </div>
                        <div className="div-image">
                            <img src="images/customers/customer 2.png" alt="" />
                        </div>
                        <div className="div-image">
                            <img src="images/customers/customer 3.png" alt="" />
                        </div>
                        <div className="div-image">
                            <img src="images/customers/customer 4.png" alt="" />
                        </div>
                        <div className="div-image">
                            <img src="images/customers/customer 5.png" alt="" />
                        </div>
                        <div className="div-image">
                            <img src="images/customers/customer 6.png" alt="" />
                        </div>
                        <div className="div-image">
                            <img src="images/customers/customer 7.png" alt="" />
                        </div>
                        <div className="div-image">
                            <img src="images/customers/customer 8.png" alt="" />
                        </div>
                        <div className="div-image">
                            <img src="images/customers/customer 9.png" alt="" />
                        </div>
                        <div className="div-image">
                            <img src="images/customers/customer 10.png" alt="" />
                        </div>
                    </SlideshowPanel>
                </div>
            </div >
        );
    }
}
