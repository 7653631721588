import React, { Component } from 'react';
import { Container } from 'reactstrap';  
import { ColumnPanel, BlogCard } from 'andorlh-ui';

export class PanelTeam extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let me = this;
        return (
            <div className="panel-team">
                <div className="div-container">
                    <div className="div-content">
                        <div>
                            <h1 class="block-title text-center">
                                Our Team
                            </h1>
                            <p >There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. </p>
                        </div> 
                    </div>
                </div>
            </div>
        );
    }
}
