import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/pages/Home';
import { FetchData } from './components/pages/FetchData';
import { Counter } from './components/pages/Counter';
import { Store } from './components/pages/Store';
import { Cart } from './components/pages/Cart';
import { Orders } from './components/pages/Orders';
import { Contact } from './components/pages/Contact';
import { Support } from './components/pages/Support';
import { Quotation } from './components/pages/Quotation';
import { Account } from './components/pages/Account';
import { SettingsProvider } from './contexts/SettingsContext';

import './custom.css'


export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);
        this.state = {
            information: {}
        }
    }

    componentDidMount() {
        this.getData();
    }

    async getData() {
        let me = this;
        const response = await fetch('home');
        const data = await response.json();
        this.setState({ information: data });
    }

    settingsProviderHandler() {
        let me = this;
        const information = this.state.information;
        //const signin = this.singIn.bind(this);
        //const signout = this.singOut.bind(this); 
        return {
            information,
            //signin,
            //signout, 
        };
    }

    render() {
        let me = this;
        let settingsProviderValue = this.settingsProviderHandler();
        return (
            <SettingsProvider value={settingsProviderValue}>
                <Layout information={this.state.data}>
                    <Route exact path='/' component={Home} />
                    <Route path='/counter' component={Counter} />
                    <Route path='/fetch-data' component={FetchData} />
                    <Route path='/Store' component={Store} />
                    <Route path='/cart' component={Cart} />
                    <Route path='/orders' component={Orders} />
                    <Route path='/contact' component={Contact} />
                    <Route path='/support' component={Support} />
                    <Route path='/quotation' component={Quotation} />
                    <Route path='/account' component={Account} />
                </Layout>
            </SettingsProvider>
        );
    }
}