export class Fetcher {

    static async sendRequest(url, data, method) {
        let me = this;
        const response = await fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        if (!response.ok) {
            const errorResponse = await response.json(); // Obtener el mensaje de error
            throw new Error(errorResponse.message || "Unknown error occurred");
        }
        const jsonResponse = await response.json();
        return jsonResponse;
    }
}