import React from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button, CustomInput } from 'reactstrap';
import { Fetcher } from '../../utils/Fetcher';
import { IoIosSend } from "react-icons/io";

export class Quotation extends React.Component {
    static displayName = Quotation.name;

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            phone: '',
            company: '',
            employees: null,
            question: '',
            acceptPolicy: false,
            sent: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    async sendMessage() {
        try {
            const { name, email, phone, company, employees, question, acceptPolicy } = this.state;

            if (!acceptPolicy) {
                alert('Debes aceptar la Política de Protección de Datos para continuar.');
                return;
            }

            let message = {
                name: name,
                email: email,
                phone: phone,
                company: company,
                employees: employees,
                question: question,
                acceptPolicy: acceptPolicy,
            }
            let response = await Fetcher.sendRequest('quotation/SendMessage', message, 'POST');
            if (response) {
                this.setState({
                    name: '',
                    email: '',
                    phone: '',
                    company: '',
                    employees: null,
                    question: '',
                    acceptPolicy: false,
                    sent: true
                });
            }
        } catch (e) {
            alert(JSON.stringify(e));
        }
    }

    handleSubmit(e) {
        e.preventDefault();

        // Validación de campos requeridos
        const { name, email, phone, acceptPolicy } = this.state;
        if (!name || !email || !phone || !acceptPolicy) {
            alert('Todos los campos marcados con * son obligatorios.');
            return;
        }

        // Envía el mensaje si todos los campos requeridos están llenos
        this.sendMessage();
    }

    handleChange(event) {
        const { name, value, type, checked } = event.target;
        this.setState({
            [name]: type === 'checkbox' ? checked : value,
        });
    }

    render() {
        let content;
        if (!this.state.sent) {
            content = (<Row className="justify-content-md-center">
                <Col md={6}>
                    <div>
                        <h1>Cotizaciones</h1>
                        <p>En SoftControlWeb, nos enorgullece ofrecer soluciones personalizadas que se adapten a tus necesidades. Si estás interesado en obtener una cotización para nuestros productos o servicios, por favor completa el siguiente formulario con la mayor cantidad de detalles posible. Esto nos permitirá proporcionarte una estimación precisa y ajustada a tus requisitos específicos. Nuestro equipo de ventas se pondrá en contacto contigo a la brevedad para discutir tus necesidades y ofrecerte la mejor opción disponible. <b>Agradecemos tu interés en nosotros y esperamos poder colaborar contigo.</b></p>
                    </div>
                </Col>
                <Col md={6}>
                    <div>
                        <Container>
                            <Row className="justify-content-md-center">
                                <Col>
                                    <Form onSubmit={this.handleSubmit}>
                                        <br />
                                        <FormGroup>
                                            <Label for="formName">Nombre  <span className="requiredField">*</span></Label>
                                            <Input
                                                type="text"
                                                name="name"
                                                id="formName"
                                                placeholder="Ingresa tu nombre"
                                                value={this.state.name}
                                                onChange={this.handleChange}
                                                required // Campo requerido
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label for="formEmail">Correo <span className="requiredField">*</span></Label>
                                            <Input
                                                type="email"
                                                name="email"
                                                id="formEmail"
                                                placeholder="Ingresa tu correo para ponernos en contacto"
                                                value={this.state.email}
                                                onChange={this.handleChange}
                                                required // Campo requerido
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label for="formPhone">Teléfono <span className="requiredField">*</span></Label>
                                            <Input
                                                type="text"
                                                name="phone"
                                                id="formPhone"
                                                placeholder="Ingresa tu teléfono para ponernos en contacto"
                                                value={this.state.phone}
                                                onChange={this.handleChange}
                                                required // Campo requerido
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label for="formCompany">Empresa <span className="requiredField">*</span></Label>
                                            <Input
                                                type="text"
                                                name="company"
                                                id="formCompany"
                                                placeholder="Ingresa el nombre de tu empresa"
                                                value={this.state.company}
                                                onChange={this.handleChange}
                                                required // Campo requerido
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label for="formEmployees">Número de empleados <span className="requiredField">*</span></Label>
                                            <Input
                                                type="number"
                                                name="employees"
                                                id="formEmployees"
                                                placeholder="Ingresa el número de empleados de tu empresa"
                                                value={this.state.employees}
                                                onChange={this.handleChange}
                                                required // Campo requerido
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label for="formQuestion">Mensaje <span className="requiredField">*</span></Label>
                                            <Input
                                                type="textarea"
                                                name="question"
                                                id="formQuestion"
                                                rows={3}
                                                placeholder="Escribe tu inquietud o necesidad"
                                                value={this.state.question}
                                                onChange={this.handleChange}
                                                required // Campo requerido
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <CustomInput
                                                type="checkbox"
                                                id="formAcceptPolicy"
                                                label="Acepto Política de Protección de Datos"
                                                name="acceptPolicy"
                                                checked={this.state.acceptPolicy}
                                                onChange={this.handleChange}
                                                required // Campo requerido
                                            />
                                        </FormGroup>

                                        <Button color="primary" type="submit">
                                            <IoIosSend /> Enviar
                                        </Button>
                                    </Form>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Col>
            </Row>)
        } else {
            content = (<Row className="justify-content-md-center">
                <Col md={6}>
                    <div>
                        <h1>¡Gracias por contactarnos!</h1>
                        <p>Hemos recibido tu mensaje y nuestro equipo se pondrá en contacto contigo a la brevedad posible. Apreciamos tu interés y estaremos encantados de ayudarte con cualquier consulta o solicitud que tengas.</p>
                    </div>
                </Col>
            </Row>);
        }
        return (
            <div className="Contact">
                <div className="div-content">
                    <Container>
                        {content}
                    </Container>
                </div>
            </div>
        );
    }
}
