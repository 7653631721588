import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { PageHeader } from './controls/PageHeader';
import { PageFooter } from './controls/PageFooter';
import SettingsContext from '../contexts/SettingsContext';

export class Layout extends Component {
    static displayName = Layout.name;
    static contextType = SettingsContext;

    constructor(props) {
        super(props);
    } 

    render() {
        let me = this;

        let footerDataSource = null;
        if (this.context.information) {
            if (this.context.information.footer) {
                footerDataSource = this.context.information.footer
            }
        }

        return ( 
             <div className="Layout">
                <section className="section-main">
                    <section className="section-header">
                        <PageHeader className="PageHeader"/>
                    </section>
                    <section className="section-content">
                        <div className="PageContent">
                            {this.props.children}
                        </div> 
                    </section>
                </section>
                <section className="section-footer">
                    <PageFooter className="PageFooter" dataSource={footerDataSource}/>
                </section> 
            </div>
        );
    }
}
