import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { SlideshowPanel, ColumnPanel, BlogCard } from 'andorlh-ui';
import { FaCrown } from "react-icons/fa";

export class PanelTestimonials extends Component {

    constructor(props) {
        super(props);
    }

    renderLabel() {
        if (!this.props.information) {
            return;
        }
        if (!this.props.information.testimonialsSection) {
            return;
        }
        return (
            <div className="div-header">
                <h1>
                    {this.props.information.testimonialsSection.label}
                </h1>
                <p >
                    {this.props.information.testimonialsSection.description}
                </p>
            </div>
        );
    }

    renderItems() {
        let elements = [];
        if (!this.props.information) {
            return;
        }
        if (!this.props.information.testimonialsSection) {
            return;
        }
        if (!this.props.information.testimonialsSection.testimonials) {
            return;
        }
        for (var i = 0; i < this.props.information.testimonialsSection.testimonials.length; i++) {
            let item = this.props.information.testimonialsSection.testimonials[i];
            elements.push(
                <div className="div-testimonial">
                    <div className="div-message">
                        <table>
                            <tr>
                                <td>
                                    <span className="span-quotes">&ldquo;</span>
                                </td>
                                <td>
                                    <p>{item.message}</p>
                                </td>
                                <td>
                                    <span className="span-quotes">&rdquo;</span>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="div-name">
                        <p>{item.name}</p>
                    </div>
                    <div className="div-position">
                        <p><span className="span-position">{item.position}</span>, <span className="span-company">{item.company}</span></p>
                    </div>
                </div>
            )
        }
        return elements;
    }

    render() {
        let me = this;
        return (
            <div className={this.props.className}>
                <div className="div-content">
                    {this.renderLabel()}
                    <SlideshowPanel className="SlideshowPanel" auto="false" timeout="7" pagination="true" mode="simple">
                        {this.renderItems()}
                    </SlideshowPanel>
                </div>
            </div>
        );
    }
}
