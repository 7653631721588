import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import { ColumnPanel, BlogCard } from 'andorlh-ui';
import { FaCrown } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import { ImPhone } from "react-icons/im";

export class PageFooter extends Component {

    constructor(props) {
        super(props);
    }

    renderCopyright() {
        let me = this;
        if (!this.props.dataSource) {
            return;
        }
        //let year = this.props.dataSource.copyright.year;
        //let company = this.props.dataSource.copyright.company;
        return (
            <ColumnPanel className="ColumnPanel" >
                {/*<div> Copyright &copy; {year} {company} es diseñado por <a href="https://thecocotouch.com/" target="_blank">TheCocoTouch</a></div>*/}
                <div>{this.props.dataSource.copyright.message}</div>
            </ColumnPanel >
        )
    }

    renderBenefits() {
        if (!this.props.dataSource) {
            return;
        }
        let elements = [];
        let items = this.props.dataSource.benefits
        if (!items) {
            return;
        }
        for (var i = 0; i < items.length; i++) {
            let item = items[i];
            elements.push(
                <div>
                    <span className="icon">
                        <FaCrown />
                    </span>
                    <span>{" "}</span>
                    <span>{item.label}</span>
                </div>
            )
        }
        return (
            <ColumnPanel className="ColumnPanel" >
                {elements}
            </ColumnPanel >
        )
    }

    renderInteractive() {
        if (!this.props.dataSource) {
            return;
        }
        if (!this.props.dataSource.interactive) {
            return;
        }
        let elements = [];
        for (var i = 0; i < this.props.dataSource.interactive.length; i++) {
            let group = this.props.dataSource.interactive[i];
            elements.push(this.renderInteractiveGroup(group));
        }
        return (
            <ColumnPanel className="ColumnPanel" >
                {elements}
            </ColumnPanel >
        )
    }

    renderInformation() {
        if (!this.props.dataSource) {
            return;
        }
        if (!this.props.dataSource.information) {
            return;
        }
        let elements = [];
        for (var i = 0; i < this.props.dataSource.information.length; i++) {
            let group = this.props.dataSource.information[i];
            elements.push(this.renderInformationGroup(group));
        }
        return (
            <ColumnPanel className="ColumnPanel" >
                {elements}
            </ColumnPanel >
        )
    }

    renderInformation2() {
        if (!this.props.dataSource) {
            return;
        }
        let elements = [];
        elements.push(this.renderLinks("Contacto", this.props.dataSource.contact));
        elements.push(this.renderLinks("Centro de ayuda", this.props.dataSource.help));
        elements.push(this.renderLinks("Nosotros", this.props.dataSource.about));
        elements.push(this.renderLinks("Información legal", this.props.dataSource.legal));
        return (
            <ColumnPanel className="ColumnPanel" >
                {elements}
            </ColumnPanel >
        )
    }

    renderInteractiveGroup(group) {
        let items = group.items;
        if (!items) {
            return;
        }
        let links = [];
        for (var i = 0; i < items.length; i++) {
            let item = items[i];
            links.push(
                <li><a href={item.url} title={item.label} target="_blank"><img src={"images/" + item.imageUrl} /></a></li>
            )
        }
        if (links.length > 0) {
            return (
                <div>
                    <h5>{group.label}</h5>
                    <ul>
                        {links}
                    </ul>
                </div>
            );
        } else {
            return null;
        }
    }

    renderInformationGroup(group) {
        let items = group.items;
        if (!items) {
            return;
        }
        let links = [];
        for (var i = 0; i < items.length; i++) {
            let item = items[i];
            let label = item.label;
            let icon = null;
            if (item.icon) {
                switch (item.icon) {
                    case "gps":
                        icon = <IoLocationSharp />;
                        break;
                    case "phone":
                        icon = <ImPhone />;
                        break;
                }
                label = (<b>{item.label}</b>);
            }
            if (label=="") {
                label = (<>&nbsp;</>);
            }
            if (item.url) {
                links.push(
                    <li><Link to={item.url} title={item.label}>{icon}{label}</Link></li>
                )
            } else { 
                links.push(
                    <li><span>{icon}{label}</span></li>
                )
            }
        }
        if (links.length > 0) {
            return (
                <div>
                    <h5>{group.label}</h5>
                    <ul>
                        {links}
                    </ul>
                </div>
            );
        } else {
            return null;
        }
    }

    renderIconLinks(label, items, iconFolder) {
        if (!items) {
            return;
        }
        let links = [];
        for (var i = 0; i < items.length; i++) {
            let item = items[i];
            links.push(
                <li><a href={item.url} title={item.label} target="_blank"><img src={"images/" + iconFolder + "/" + this.removeBlankSpaces(item.label) + ".png"} /></a></li>
            )
        }
        if (links.length > 0) {
            return (
                <div>
                    <h5>{label}</h5>
                    <ul>
                        {links}
                    </ul>
                </div>
            );
        } else {
            return null;
        }
    }

    renderLinks(label, items) {
        if (!items) {
            return;
        }
        let links = [];
        for (var i = 0; i < items.length; i++) {
            let item = items[i];
            links.push(
                <li><a href={item.url} title={item.label} target="_blank">{item.label}</a></li>
            )
        }
        if (links.length > 0) {
            return (
                <div>
                    <h4>{label}</h4>
                    <ul>
                        {links}
                    </ul>
                </div>
            );
        } else {
            return null;
        }
    }

    removeBlankSpaces(text) {
        return text.replace(/\s/g, '');
    }

    render() {
        return (
            <div className={this.props.className}>
                <div className="div-content">
                    <div className="div-benefits" >
                        <div className="div-content">
                            {this.renderBenefits()}
                        </div>
                    </div>
                    <div className="div-information" >
                        <div className="div-content">
                            {this.renderInformation()}
                        </div>
                    </div>
                    <div className="div-interactive" >
                        <div className="div-content">
                            {this.renderInteractive()}
                        </div>
                    </div>
                    <div className="div-copyright" >
                        <div className="div-content">
                            {this.renderCopyright()}
                        </div>
                    </div>
                </div >
            </div >
        );
    }
}
