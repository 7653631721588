import React from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button, CustomInput } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Fetcher } from '../../utils/Fetcher';
import { IoIosSend } from "react-icons/io";

export class Support extends React.Component {
    static displayName = Support.name;

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            phone: '',
            identification: '',
            company: '',
            product: '',
            service: '',
            question: '',
            place: '',
            acceptPolicy: false,
            sent: false,
            ticket:0
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    async sendMessage() {
        try {
            const { name, email, phone, identification, company, product, service, question, place, acceptPolicy } = this.state;

            if (!acceptPolicy) {
                alert('Debes aceptar la Política de Protección de Datos para continuar.');
                return;
            }

            let message = {
                name: name,
                email: email,
                phone: phone,
                identification: identification,
                company: company,
                product: product,
                service: service,
                question: question,
                place: place,
                acceptPolicy: acceptPolicy,
            }
            let response = await Fetcher.sendRequest('support/SendMessage', message, 'POST');
            if (response) {
                this.setState({
                    name: '',
                    email: '',
                    phone: '',
                    identification: '',
                    company: '',
                    product: '',
                    service: '',
                    question: '',
                    place: '',
                    acceptPolicy: false,
                    sent: true,
                    ticket: response.id
                });
                window.scrollTo(0, 0);
            }


        } catch (e) {
            let k = 0;
            alert(e.message);
        }
    }

    handleSubmit(e) {
        e.preventDefault();

        // Validación de campos requeridos
        const { name, email, phone, identification, company, question, place, acceptPolicy } = this.state;
        if (!name || !email || !phone || !identification || !company || !question || !place || !acceptPolicy) {
            alert('Todos los campos marcados con * son obligatorios.');
            return;
        }

        // Envía el mensaje si todos los campos requeridos están llenos
        this.sendMessage();
    }

    handleChange(event) {
        const { name, value, type, checked } = event.target;
        this.setState({
            [name]: type === 'checkbox' ? checked : value,
        });
    }

    render() {
        let content;
        if (!this.state.sent) {
            content = (<Row className="justify-content-md-center">
                <Col md={6}>
                    <div>
                        <h1>Solicitudes</h1>
                        <p>En SoftControlWeb, nos importa mantener una comunicación cercana con nuestros clientes y estamos dedicados a ofrecerte la mejor atención. Si tienes dudas o requieres asistencia técnica, te invitamos a comunicarte con nosotros a través del formulario a continuación. Nuestro equipo de soporte está disponible para brindarte ayuda y resolver tus consultas con prontitud. No olvides incluir tus datos de contacto para que podamos atenderte de manera ágil.<b> ¡Agradecemos tu confianza en nosotros!</b></p>
                    </div>
                </Col>
                <Col md={6}>
                    <div>
                        <Container>
                            <Row className="justify-content-md-center">
                                <Col>
                                    <Form onSubmit={this.handleSubmit}>
                                        <br />
                                        <FormGroup>
                                            <Label for="formName">Nombre  <span className="requiredField">*</span></Label>
                                            <Input
                                                type="text"
                                                name="name"
                                                id="formName"
                                                placeholder="Ingresa tu nombre"
                                                value={this.state.name}
                                                onChange={this.handleChange}
                                                required // Campo requerido
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label for="formEmail">Correo <span className="requiredField">*</span></Label>
                                            <Input
                                                type="email"
                                                name="email"
                                                id="formEmail"
                                                placeholder="Ingresa tu correo para ponernos en contacto"
                                                value={this.state.email}
                                                onChange={this.handleChange}
                                                required // Campo requerido
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label for="formPhone">Teléfono <span className="requiredField">*</span></Label>
                                            <Input
                                                type="text"
                                                name="phone"
                                                id="formPhone"
                                                placeholder="Ingresa tu teléfono para ponernos en contacto"
                                                value={this.state.phone}
                                                onChange={this.handleChange}
                                                required // Campo requerido
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label for="formCompany">Nit <span className="requiredField">*</span></Label>
                                            <Input
                                                type="text"
                                                name="identification"
                                                id="formIdentification"
                                                placeholder="Ingresa el NIT sin dígito de verificación"
                                                value={this.state.identification}
                                                onChange={this.handleChange}
                                                required // Campo requerido
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label for="formCompany">Empresa <span className="requiredField">*</span></Label>
                                            <Input
                                                type="text"
                                                name="company"
                                                id="formCompany"
                                                placeholder="Ingresa el nombre de tu empresa"
                                                value={this.state.company}
                                                onChange={this.handleChange}
                                                required // Campo requerido
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label for="formProduct">Producto <span className="requiredField">*</span></Label>
                                            <Input
                                                type="select"
                                                name="product"
                                                value={this.state.product}
                                                onChange={this.handleChange}
                                                required>
                                                <option value="">Seleccionar...</option>
                                                <option value="SCW Empleados">SCW Empleados</option>
                                                <option value="SCW Autogestión">SCW Autogestión</option>
                                                <option value="SCW Visitantes">SCW Visitantes</option>
                                            </Input>
                                        </FormGroup>

                                        <FormGroup>
                                            <Label for="formService">Servicio <span className="requiredField">*</span></Label>
                                            <Input
                                                type="select"
                                                name="service"
                                                value={this.state.service}
                                                onChange={this.handleChange}
                                                required>
                                                <option value="">Seleccionar...</option>
                                                <option value="Soporte">Soporte</option>
                                                <option value="Capacitación">Capacitación</option>
                                                <option value="Demostración">Demostración</option>
                                                <option value="Desarrollo">Desarrollo</option>
                                            </Input>
                                        </FormGroup>

                                        <FormGroup>
                                            <Label for="formQuestion">Mensaje <span className="requiredField">*</span></Label>
                                            <Input
                                                type="textarea"
                                                name="question"
                                                id="formQuestion"
                                                rows={3}
                                                placeholder="Escribe tu inquietud o necesidad"
                                                value={this.state.question}
                                                onChange={this.handleChange}
                                                required // Campo requerido
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label for="formPlace">Sede <span className="requiredField">*</span></Label>
                                            <Input
                                                type="text"
                                                name="place"
                                                id="formPlace"
                                                placeholder="Ingresa el nombre de la sede donde se prestará el servicio"
                                                value={this.state.place}
                                                onChange={this.handleChange}
                                                required // Campo requerido
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <CustomInput
                                                type="checkbox"
                                                id="formAcceptPolicy"
                                                label="Acepto Política de Protección de Datos"
                                                name="acceptPolicy"
                                                checked={this.state.acceptPolicy}
                                                onChange={this.handleChange}
                                                required // Campo requerido
                                            />
                                        </FormGroup>

                                        <FormGroup>

                                            <Button color="primary" type="submit">
                                                <IoIosSend /> Enviar
                                            </Button>
                                            <br />
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Col>
            </Row>);
        } else {
            content = (<Row className="justify-content-md-center">
                <Col md={6}>
                    <div>
                        <h1>¡Gracias por contactarnos!</h1>
                        <p>Hemos recibido tu solicitud con el ticket <b> N° {this.state.ticket} </b>. Nuestro equipo se pondrá en contacto contigo a la brevedad posible. Apreciamos tu interés y estaremos encantados de ayudarte con cualquier consulta o solicitud que tengas.</p>
                    </div>
                     
                    <Link className="button-1" to="/">Volver al inicio</Link>
                    
                </Col>
            </Row>);
        }
        return (
            <div className="Contact">
                <div className="div-content">
                    <Container>
                        {content}
                    </Container>
                </div>
            </div>
        );
    }
}
