import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import { ColumnPanel, SlideshowPanel, BlogCard } from 'andorlh-ui';
import { IconProvider } from '../../utils/IconProvider';
import { MdOutlinePhoneAndroid } from "react-icons/md";
import { BiSolidReport } from "react-icons/bi";
import { FaClipboardList } from "react-icons/fa6";
import { BsFillPersonBadgeFill } from "react-icons/bs";
import { IoPeople } from "react-icons/io5";
import { PiFingerprintSimpleFill } from "react-icons/pi";
import { SiMinutemailer } from "react-icons/si";
import { FaGears } from "react-icons/fa6";

export class PanelSolutions extends Component {

    constructor(props) {
        super(props);
    }

    getIcon(name) {
        switch (name) {
            case "smartphone":
                return <MdOutlinePhoneAndroid />;
            case "report":
                return <BiSolidReport />;
            case "task":
                return <FaClipboardList />;
            case "person":
                return <BsFillPersonBadgeFill />;
            case "people":
                return <IoPeople />;
            case "biometric":
                return <PiFingerprintSimpleFill />;
            case "mail":
                return <SiMinutemailer />;
            case "system":
                return <FaGears />;
        }
        return null;
    }

    renderHeader() {
        let elements = [];
        if (!this.props.information) {
            return;
        }
        if (this.props.information.label) {
            elements.push(
                <h1>
                    {this.props.information.label}
                </h1>
            );
        }
        if (this.props.information.description) {
            elements.push(
                <p >
                    {this.props.information.description}
                </p>
            );
        }
        return (
            <div className="div-header">
                {elements}
            </div>
        );
    }

    renderModules() {
        let elements = [];
        if (!this.props.information) {
            return;
        }
        if (!this.props.information.modules) {
            return;
        }
        for (var i = 0; i < this.props.information.modules.length; i++) {
            let item = this.props.information.modules[i];
            elements.push(
                <div className="div-solution">
                    <div className="div-information">
                        <h2>{item.brand}</h2>
                        <h1>{item.name}{" "}{this.getIcon(item.icon)}</h1>
                        <p>{item.description}</p>
                        <Link className="button-1" to="/quotation">Empieza ahora</Link>
                    </div>
                </div>
            )
        }
        return elements;
    }

    renderFeatures() {
        let elements = [];
        if (!this.props.information) {
            return;
        }
        if (!this.props.information.features) {
            return;
        }
        for (var i = 0; i < this.props.information.features.length; i++) {
            let item = this.props.information.features[i];
            elements.push(
                <div className="div-benefit">
                    <h2>{this.getIcon(item.icon)}</h2>
                    <h5>
                        {/*<span className="icon">*/}
                        {/*    <FaCrown />*/}
                        {/*</span>*/}
                        {/*<span>{" "}</span>*/}
                        <span>{item.name}</span>
                    </h5>
                    <div>
                        <p>{item.description}</p>
                    </div>
                </div>
            )
        }
        return elements;
    }

    render() {
        let me = this;
        return (
            <div className={this.props.className}>
                <div className="div-content">
                    {this.renderHeader()}
                    <SlideshowPanel className="SlideshowPanel" auto="false" timeout="7" pagination="true">
                        {this.renderModules()}
                    </SlideshowPanel>
                    <br />
                    <ColumnPanel className="ColumnPanel" >
                        {this.renderFeatures()}
                    </ColumnPanel>
                </div>
            </div >
        );
    }
}
